(() => {
  function generateHtml(template, data) {
    return template(data)
  }
  async function request(url, opt) {
    return await fetch(url, opt).then(res => res.json())
  }
  function localStorageData(name, data) {
    let itemData = localStorage.getItem(name);

    if (!itemData && data) {
      itemData = localStorage.setItem(name, data);
    }

    return itemData
  }
  async function getDataFromGithub() {
    const DivRepos = document.getElementById('js-repos')
    const DivGists = document.getElementById('js-gists')
    const { repos_url, public_repos, url, public_gists } = await request('https://api.github.com/users/s-kobets')

    let dataRepos = JSON.parse(localStorageData('repos'))
    let dataGists = JSON.parse(localStorageData('gists'))

    if (!dataRepos) {
      dataRepos = await request(`${repos_url}?per_page=${public_repos}`)
      localStorageData('repos', JSON.stringify(dataRepos))
    }
    if (!dataGists) {
      dataGists = await request(`${url}/gists?per_page=${public_gists}`)
      localStorageData('gists', JSON.stringify(dataGists))
    }

    const excludeRepos = ['kulazhenko-site', 'Article']
    const HtmlRepos = dataRepos
      .filter(item => !item.fork && !excludeRepos.includes(item.name))
      .sort((left, right) => { const dateLeft = new Date(left.updated_at); const dateRight = new Date(right.updated_at); if (dateLeft < dateRight) return 1; if (dateLeft > dateRight) return -1; return 0; })
      .map(item => generateHtml(({ html_url, name, updated_at, language }) => `<tr><td><a href="${html_url}" target="_blank" rel="noopener noreferrer">${name}</a></td><td>${language}</td><td>${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(updated_at))}</td></tr>`, item))

    DivRepos.innerHTML = generateHtml((body) => `<table><thead><tr><th>name</name><th>language</th><th>last update</th></tr></thead><tbody>${body}</tbody></table>`, HtmlRepos.join(''))

    const HtmlGists = dataGists.map(item => generateHtml(({ html_url, description, updated_at }) => `<tr><td><a href="${html_url}" target="_blank" rel="noopener noreferrer">${description}</a></td><td>${new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(updated_at))}</td></tr>`, item))
    DivGists.innerHTML = generateHtml((body) => `<table><thead><tr><th>name</name><th>last update</th></tr></thead><tbody>${body}</tbody></table>`, HtmlGists.join(''))
  }

  document.addEventListener('DOMContentLoaded', async function () {
    getDataFromGithub()
  }, false);
})()